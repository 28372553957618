:root {
  --color-background: #222;
  --color-text: #acacac;
  --color-text-dimmed: #6B6B6B;
  --color-text-accent: linen;
  /* --color-link: dodgerblue; */
  --color-visited: #ba73dd;
  --color-background-code: #343434dd;
  --color-mark: #00ffff40;
  --color-indentation-guide-quotation: #b264ea;
  --color-octicon: #d1d7e0;
  --color-border: #59595A;
}

html {
  text-shadow: revert;
}

/* layout */
hr {
  border-style: outset;
}

/* text */
mark {
  mix-blend-mode: screen;
}

:not(pre) > code {
  mix-blend-mode: lighten;
}
